<template>
  <div class="toggle-radio">
    <template
      v-for="(option, index) in computedOptions"
      :key="`${option.value}`"
    >
      <input
        :id="`${name}-${option.value || option.label}`"
        v-model="value"
        class="sr-only"
        type="radio"
        :name="name"
        :value="option.value"
      />
      <label
        :for="`${name}-${option.value || option.label}`"
        :class="[
          itemsClass,
          value === option.value ? 'active' : '',
          index === 0 ? '' : 'border-l'
        ]"
      >
        {{ option.label || option.value }}
      </label>
    </template>
  </div>
</template>

<script setup lang="ts">
interface ToggleRadioOption {
  label: string;
  value: string | number | boolean;
}

const props = defineProps({
  modelValue: {
    type: [String, Number, Boolean] as PropType<string | number | boolean>
  },
  options: {
    type: Array as PropType<(ToggleRadioOption | string)[]>,
    default: () => []
  },
  name: {
    type: String,
    default: 'default'
  },
  itemsClass: {
    type: String,
    default: ''
  }
});

const computedOptions = computed<ToggleRadioOption[]>(() => {
  return props.options.map(option => {
    if (typeof option !== 'object') {
      return {
        label: option,
        value: option
      };
    }
    return option;
  });
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string | number | boolean): void;
}>();

const value = ref(props.modelValue);

watch(
  () => props.modelValue,
  newValue => {
    if (newValue !== value.value) {
      value.value = newValue;
    }
  }
);

watch(value, newValue => {
  emit('update:modelValue', newValue);
});
</script>

<style lang="scss">
.toggle-radio {
  @apply inline-flex justify-stretch transition-all;
  @apply outline outline-transparent outline-2 -outline-offset-2;

  label {
    @apply flex-1 flex items-center justify-center cursor-pointer;
  }

  &.variant-home,
  &:not([class*='variant-']) {
    @apply min-h-[64px]
      rounded overflow-hidden;

    &:has(:focus-visible) {
      @apply outline-white;
    }

    label {
      @apply min-w-[48px] basis-[auto]
        font-sans leading-tight
        transition-all
        select-none
        min-h-full
        border-gray-lighter;
      &.active {
        @apply text-primary font-bold bg-gray-lighter;
      }
      &:not(.active) {
        @apply text-neutral bg-white hover:bg-gray-lighter;
      }
    }
  }

  &.variant-home-dark {
    @apply min-h-[64px]
      rounded overflow-hidden;

    &:has(:focus-visible) {
      @apply ring-2 ring-white/50;
    }

    label {
      @apply min-w-[48px] basis-[auto]
        font-sans font-bold leading-tight
        transition-all
        select-none
        min-h-full
        border-gray-lighter;
      &.active {
        @apply text-primary bg-white;
      }
      &:not(.active) {
        @apply text-white bg-black/30;
      }
    }
  }

  &.variant-outline {
    @apply h-[48px]
      rounded overflow-hidden
      bg-white
      border-[1px] border-line;

    &:has(:focus-visible) {
      @apply ring-2 ring-line/50;
    }

    label {
      @apply px-4 min-w-[48px] basis-[auto]
        font-sans leading-tight
        text-base
        transition-all
        select-none
        min-h-full
        border-line;
      &.active {
        @apply text-white bg-neutral-lighter;
      }
      &:not(.active) {
        @apply text-neutral bg-transparent hover:bg-neutral-lighter/20;
      }
    }
  }
}
</style>
